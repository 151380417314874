@import "../resources/css/colors";
@import "../resources/css/breakpoints";


.HeaderPlaceholder {
  @media(min-width: $xs) {
    height: 75px;
  }

  @media(min-width: $sm) {
    height: 90px;
  }
}