@import "../../resources/css/colors";
@import "../../resources/css/breakpoints";

.contactContainerMobile {
  padding: 0;

  &Item {
    padding: 0;
    height: fit-content;
  }

  .contactGrid {
    position: relative;
    background-color: $fandic-background;
    //box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);

    &ImageContainer {
      position: relative;
      width: 100%;

      &Image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &Title {
        position: absolute;
        left: 20px;
        bottom: -20px;
        padding: 12px 20px;
        font-size: 25px !important;
        font-weight: 700 !important;
        font-family: "Roboto Mono", sans-serif !important;
        width: fit-content;

        color: $fandic-text;
        background-color: $fandic-primary;
      }
    }

    &TextContainer {
      padding: 45px 40px 40px;

      &Text {
        font-size: 16px !important;
        font-weight: normal !important;
        font-family: "Inconsolata", "sans-serif" !important;

        color: $fandic-text;
      }

      .contactButtonList {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 20px;

        .contactButton {
          margin: 10px 0;
          padding: 10px 20px;
          font-size: 16px;
          font-weight: 600;
          font-family: "Roboto Mono", sans-serif;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          transition: 0.3s;
          width: 75%;
          min-width: fit-content;

          &Phone {
            color: white;
            background-color: $phoneGreen;
          }

          &Instagram {
            color: white;
            background-color: $instagram;
          }

            &Facebook {
                color: white;
                background-color: $facebook;
            }
        }
      }
    }
  }
}