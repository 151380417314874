@import "../../resources/css/colors";
@import "../../resources/css/breakpoints";

.imprintPage {
  background-color: $fandic-background !important;
  padding: 80px !important;
  margin: 20px !important;

  &Title {
    padding: 12px 20px;
    font-size: 30px !important;
    font-weight: 700 !important;
    font-family: "Roboto Mono", sans-serif !important;
    width: fit-content;

    color: $fandic-text;
    background-color: $fandic-primary;
  }

  &SubTitle {
    padding: 10px 18px;
    margin-bottom: 30px !important;
    font-size: 25px !important;
    font-weight: 700 !important;
    font-family: "Roboto Mono", sans-serif !important;
    color: $fandic-text !important;
    width: fit-content;
    background-color: $fandic-secondary;
  }

  &SubSubTitle {
    margin-bottom: 20px !important;
    font-size: 20px !important;
    font-weight: 700 !important;
    font-family: "Roboto Mono", sans-serif !important;
    color: $fandic-text !important;
  }

  &Text {
    margin-left: 25px !important;
    font-size: 16px !important;
    font-weight: normal !important;
    font-family: "Inconsolata", "sans-serif" !important;
    margin-bottom: 20px !important;

    color: $fandic-text !important;

    &Secondary {
      font-size: 16px !important;
      font-weight: normal !important;
      font-family: "Inconsolata", "sans-serif" !important;
      margin-bottom: 20px !important;
      color: $fandic-text-secondary !important;
    }
  }

  &ImprintContainer {
    padding-top: 40px !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  &DSGVOContainer {
    padding-left: 40px !important;
    padding-right: 40px !important;

    h2 {
        margin-top: 80px;
        padding: 10px 18px;
        font-size: 25px !important;
        font-weight: 700 !important;
        font-family: "Roboto Mono", sans-serif !important;
        color: $fandic-text !important;
        width: fit-content;
        background-color: $fandic-secondary;
    }

    h3 {
        font-size: 20px !important;
        font-weight: 700 !important;
        font-family: "Roboto Mono", sans-serif !important;
        color: $fandic-text !important;
    }

    h4 {
      margin-left: 5px !important;
        font-size: 15px !important;
        font-weight: 700 !important;
        font-family: "Roboto Mono", sans-serif !important;
        color: $fandic-text !important;
    }

    p, li {
        margin-left: 25px !important;
        font-family: "Inconsolata", "sans-serif" !important;
        color: $fandic-text !important;
    }

    a {
        font-family: "Inconsolata", "sans-serif" !important;
        color: $fandic-secondary !important;
        text-decoration: none;
    }
  }
}