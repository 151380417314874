.aboutMeContainerMobile {
  padding: 0;
}
.aboutMeContainerMobileItem {
  padding: 0;
  height: fit-content;
}
.aboutMeContainerMobile .aboutMeGrid {
  position: relative;
  background-color: #0C090A;
}
.aboutMeContainerMobile .aboutMeGridImageContainer {
  position: relative;
  width: 100%;
}
.aboutMeContainerMobile .aboutMeGridImageContainerImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.aboutMeContainerMobile .aboutMeGridImageContainerTitle {
  position: absolute;
  left: 20px;
  bottom: -20px;
  padding: 12px 20px;
  font-size: 25px !important;
  font-weight: 700 !important;
  font-family: "Roboto Mono", sans-serif !important;
  width: fit-content;
  color: white;
  background-color: #EA5F9D;
}
.aboutMeContainerMobile .aboutMeGridTextContainer {
  padding: 55px 40px 40px;
}
.aboutMeContainerMobile .aboutMeGridTextContainerText {
  font-size: 16px !important;
  font-weight: normal !important;
  font-family: "Inconsolata", "sans-serif" !important;
  color: white;
}

