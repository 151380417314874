.editGalleryImageDialogPaper {
  background-color: #0C090A !important;
  border-radius: 0 !important;
  overflow: visible !important;
}
.editGalleryImageDialogContent {
  margin-top: 40px;
}
.editGalleryImageDialogTitle {
  position: absolute;
  left: -20px;
  top: -20px;
  background-color: #2387D0;
  width: fit-content;
  margin-bottom: 20px;
  padding: 12px 18px;
  font-size: 20px !important;
  font-weight: 700 !important;
  font-family: "Roboto Mono", sans-serif !important;
  color: white;
}
.editGalleryImageDialogPreviewText {
  font-size: 14px !important;
  font-weight: 700 !important;
  font-family: "Roboto Mono", sans-serif !important;
  color: #9e9e9e !important;
  margin-bottom: 5px !important;
  text-align: center;
}
.editGalleryImageDialogImage {
  width: 100%;
  height: auto;
}
.editGalleryImageDialogInput {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.editGalleryImageDialogInputTitle {
  margin-bottom: 10px !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  font-family: "Roboto Mono", sans-serif !important;
  color: white !important;
}
.editGalleryImageDialogInputTitleSmall {
  margin-bottom: 20px !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  font-family: "Roboto Mono", sans-serif !important;
  color: white !important;
}
.editGalleryImageDialogInputInput {
  color: white !important;
}
.editGalleryImageDialogInputInput:before {
  border-bottom: 1px solid lightgray !important;
}
.editGalleryImageDialogInputInput:after {
  border-bottom: 2px solid white !important;
}
.editGalleryImageDialogInputInputIcon {
  color: white !important;
}
.editGalleryImageDialogInputLabel {
  color: lightgray !important;
}
.editGalleryImageDialogInputLabel:focus {
  color: white !important;
}
.editGalleryImageDialogInputButton {
  margin-bottom: 20px !important;
  width: 100% !important;
  background-color: #007DC5 !important;
  color: white !important;
  border-radius: 0 !important;
}
.editGalleryImageDialogInputButtonDelete {
  background-color: #be3939 !important;
}
.editGalleryImageDialogInputButtonUpload {
  margin-bottom: 0 !important;
}

