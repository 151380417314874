@import "../../resources/css/colors";
@import "../../resources/css/breakpoints";

.milestoneImageDialog {
  width: 100%;
  margin: 0;
  padding: 0;

  &Paper {
    background-color: transparent !important;
    border-radius: 0 !important;
    margin: 15px !important;
  }

  &Content {
    margin: 0 !important;
    padding: 0 !important;
  }

  &Image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}