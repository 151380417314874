.aboutMeSettingImageTitle {
  margin-bottom: 25px !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  font-family: "Roboto Mono", sans-serif !important;
  color: white !important;
}
.aboutMeSettingImageForm {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.aboutMeSettingImageFormCurrentImage {
  max-width: 100%;
  max-height: 30vh;
  object-fit: cover;
  margin-bottom: 10px;
}
.aboutMeSettingImageFormInfo {
  margin-top: 10px !important;
}
.aboutMeSettingImageFormInfoConfirm {
  color: #4dda4d !important;
  font-size: 14px !important;
}
.aboutMeSettingImageFormInfoError {
  color: #a91919 !important;
  font-size: 14px !important;
}
.aboutMeSettingImageFormPreviewText {
  font-size: 14px !important;
  font-weight: 700 !important;
  font-family: "Roboto Mono", sans-serif !important;
  color: #9e9e9e !important;
  margin-bottom: 5px !important;
}
.aboutMeSettingImageFormFileUploadButton {
  background-color: #007DC5 !important;
  color: white !important;
  display: block;
  margin-top: 20px !important;
  width: 100%;
}
.aboutMeSettingImageFormSubmitButton {
  background-color: #007DC5 !important;
  color: white !important;
  display: block;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  width: 100%;
}
.aboutMeSettingDivider {
  margin: 20px 0 !important;
  border-bottom: 0.75px solid white !important;
}
.aboutMeSettingTextTitle {
  margin: 25px 0 !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  font-family: "Roboto Mono", sans-serif !important;
  color: white !important;
}
.aboutMeSettingTextFormTextInputProps {
  color: white !important;
}
.aboutMeSettingTextFormTextInputProps:before {
  border-bottom: 1px solid lightgray !important;
}
.aboutMeSettingTextFormTextInputProps:after {
  border-bottom: 2px solid white !important;
}
.aboutMeSettingTextFormTextInputLabel {
  color: #9e9e9e !important;
}
.aboutMeSettingTextFormTextInputLabel:focus {
  color: white !important;
}
.aboutMeSettingTextFormInfo {
  margin-top: 10px !important;
  margin-bottom: -10px !important;
}
.aboutMeSettingTextFormInfoConfirm {
  color: #4dda4d !important;
  font-size: 14px !important;
}
.aboutMeSettingTextFormInfoError {
  color: #a91919 !important;
  font-size: 14px !important;
}
.aboutMeSettingTextFormSubmitButton {
  background-color: #007DC5 !important;
  color: white !important;
  display: block;
  margin-top: 20px !important;
  margin-bottom: 10px !important;
  width: 100%;
}
.aboutMeSettingMilestonesTitle {
  margin-bottom: 25px !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  font-family: "Roboto Mono", sans-serif !important;
  color: white !important;
}
.aboutMeSettingMilestonesAddMilestoneButton {
  background-color: #007DC5 !important;
  color: white !important;
  display: block;
  margin-bottom: 10px !important;
  margin-top: 10px !important;
  width: 100%;
}

