@import "../../resources/css/colors";
@import "../../resources/css/breakpoints";


.aboutMeContainerMobile {
  padding: 0;

  &Item {
    padding: 0;
    height: fit-content;
  }

  .aboutMeGrid {
    position: relative;
    background-color: $fandic-background;
    //box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4); // Schatten hinzugefügt

    &ImageContainer {
      position: relative; // Hinzugefügt
      width: 100%;

      &Image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }


      &Title {
        position: absolute;
        left: 20px;
        bottom: -20px;
        padding: 12px 20px;
        font-size: 25px !important;
        font-weight: 700 !important;
        font-family: "Roboto Mono", sans-serif !important;
        width: fit-content;


        color: $fandic-text;
        background-color: $fandic-primary;
      }
    }

    &TextContainer {

      padding: 55px 40px 40px;

      &Text {
        font-size: 16px !important;
        font-weight: normal !important;
        font-family: "Inconsolata", "sans-serif" !important;

        color: $fandic-text;
      }

    }
  }
}