.editMilestoneDialogPaper {
  background-color: #0C090A !important;
  border-radius: 0 !important;
  overflow: visible !important;
}
.editMilestoneDialogContent {
  margin-top: 40px;
}
.editMilestoneDialogTitle {
  position: absolute;
  left: -20px;
  top: -20px;
  background-color: #2387D0;
  width: fit-content;
  margin-bottom: 20px;
  padding: 12px 18px;
  font-size: 20px !important;
  font-weight: 700 !important;
  font-family: "Roboto Mono", sans-serif !important;
  color: white;
}
.editMilestoneDialogPreviewText {
  font-size: 14px !important;
  font-weight: 700 !important;
  font-family: "Roboto Mono", sans-serif !important;
  color: #9e9e9e !important;
  margin-bottom: 5px !important;
  text-align: center;
}
.editMilestoneDialogImage {
  width: 100%;
  height: auto;
}
.editMilestoneDialogInput {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.editMilestoneDialogInputTitle {
  margin-bottom: 10px !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  font-family: "Roboto Mono", sans-serif !important;
  color: white !important;
}
.editMilestoneDialogInputTitleSmall {
  margin-bottom: 20px !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  font-family: "Roboto Mono", sans-serif !important;
  color: white !important;
}
.editMilestoneDialogInputInput {
  color: white !important;
}
.editMilestoneDialogInputInput:before {
  border-bottom: 1px solid lightgray !important;
}
.editMilestoneDialogInputInput:after {
  border-bottom: 2px solid white !important;
}
.editMilestoneDialogInputInputIcon {
  color: white !important;
}
.editMilestoneDialogInputLabel {
  color: lightgray !important;
}
.editMilestoneDialogInputLabel:focus {
  color: white !important;
}
.editMilestoneDialogInputButton {
  margin-bottom: 20px !important;
  width: 100% !important;
  background-color: #007DC5 !important;
  color: white !important;
  border-radius: 0 !important;
}
.editMilestoneDialogInputButtonDelete {
  background-color: #be3939 !important;
}
.editMilestoneDialogInputButtonUpload {
  margin-bottom: 0 !important;
}

