@import "../../resources/css/colors";
@import "../../resources/css/breakpoints";

.settingsBox {
  padding: 20px;

  &Title {
    margin: 0 15px 15px !important;
    font-size: 25px !important;
    font-weight: 700 !important;
    font-family: "Roboto Mono", sans-serif !important;
    color: $fandic-text !important;
  }

  &Accordion {
   color: $fandic-text !important;
    background-color: $fandic-background-light !important;

    &Summary {
      &Icon {
        color: $fandic-text !important;
      }
    }
  }
}