.contactContainerMobile {
  padding: 0;
}
.contactContainerMobileItem {
  padding: 0;
  height: fit-content;
}
.contactContainerMobile .contactGrid {
  position: relative;
  background-color: #0C090A;
}
.contactContainerMobile .contactGridImageContainer {
  position: relative;
  width: 100%;
}
.contactContainerMobile .contactGridImageContainerImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.contactContainerMobile .contactGridImageContainerTitle {
  position: absolute;
  left: 20px;
  bottom: -20px;
  padding: 12px 20px;
  font-size: 25px !important;
  font-weight: 700 !important;
  font-family: "Roboto Mono", sans-serif !important;
  width: fit-content;
  color: white;
  background-color: #EA5F9D;
}
.contactContainerMobile .contactGridTextContainer {
  padding: 45px 40px 40px;
}
.contactContainerMobile .contactGridTextContainerText {
  font-size: 16px !important;
  font-weight: normal !important;
  font-family: "Inconsolata", "sans-serif" !important;
  color: white;
}
.contactContainerMobile .contactGridTextContainer .contactButtonList {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.contactContainerMobile .contactGridTextContainer .contactButtonList .contactButton {
  margin: 10px 0;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  font-family: "Roboto Mono", sans-serif;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
  width: 75%;
  min-width: fit-content;
}
.contactContainerMobile .contactGridTextContainer .contactButtonList .contactButtonPhone {
  color: white;
  background-color: #4CAF50;
}
.contactContainerMobile .contactGridTextContainer .contactButtonList .contactButtonInstagram {
  color: white;
  background-color: #E1306C;
}
.contactContainerMobile .contactGridTextContainer .contactButtonList .contactButtonFacebook {
  color: white;
  background-color: #3B5998;
}

