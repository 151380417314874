@import "../../resources/css/colors";
@import "../../resources/css/breakpoints";

.aboutMeSection {
  background-color: $fandic-background !important;
  box-shadow: black 0px 0px 15px 0px !important;

  &Image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &Title {
    position: absolute;
    left: -50px;
    top: -20px;
    padding: 12px 20px;
    font-size: 30px !important;
    font-weight: 700 !important;
    font-family: "Roboto Mono", sans-serif !important;
    width: fit-content;


    color: $fandic-text;
    background-color: $fandic-primary;

    &:hover {
      cursor: pointer;
    }
  }

  &TextContainer {
    position: relative;
    padding: 80px 60px 60px;
    height: fit-content;
  }

  &Text {
    font-size: 16px !important;
    font-weight: normal !important;
    font-family: "Inconsolata", "sans-serif" !important;

    color: $fandic-text;
  }
}