@import "../../resources/css/colors";
@import "../../resources/css/breakpoints";

.contactSection {
  background-color: $fandic-background !important;
  padding: 40px 40px 80px 40px !important;

  box-shadow: black 0px 0px 15px 0px !important;

  &TextContainer {
    position: relative !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
  }

  &Title {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 40px;
    padding: 12px 20px;
    font-size: 30px !important;
    font-weight: 700 !important;
    font-family: "Roboto Mono", sans-serif !important;
    width: max-content !important;

    color: $fandic-text;
    background-color: $fandic-primary;
  }

  &Text {
    font-size: 16px !important;
    font-weight: normal !important;
    font-family: "Inconsolata", "sans-serif" !important;
    margin: 20px !important;
    text-align: center !important;

    color: $fandic-text;
  }

  &ButtonContainer {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    margin-top: 20px !important;
  }

  &Button {
    @media (max-width: $sd - 1px) {
      margin: 0 10px !important;
      padding: 15px 25px !important;
      font-size: 16px !important;
    }

    @media (min-width: $sd) {
      margin: 0 20px !important;
      padding: 10px 20px !important;
      font-size: 18px !important;
    }

    font-weight: 600 !important;
    font-family: "Roboto Mono", sans-serif !important;
    border: none !important;
    border-radius: 5px !important;
    cursor: pointer !important;
    transition: 0.3s !important;
    width: 25% !important;
    min-width: fit-content !important;

    &Phone {
      color: white;
      background-color: $phoneGreen !important;

      &:hover {
          background-color: lighten($phoneGreen, 5%) !important;
      }
    }

    &Instagram {
      color: white;
      background-color: $instagram !important;

      &:hover {
        background-color: lighten($instagram, 5%) !important;
      }
    }

    &Facebook {
      color: white;
      background-color: $facebook !important;

      &:hover {
        background-color: lighten($facebook, 5%) !important;
      }
    }

    &Form {
      color: white;
      background-color: $contactForm !important;
      margin-top: 140px !important;
      width: calc(75% + 80px) !important;

        &:hover {
          background-color: lighten($contactForm, 5%) !important;
        }
    }
  }
}