@import "../../resources/css/colors";
@import "../../resources/css/breakpoints";

.aboutMeSecretDialog {
  &Paper {
    padding-top: 50px !important;
    background-color: transparent !important;
    overflow: visible !important;
    width: 100% !important;
    height: 100% !important;
    max-width: 100vw !important;
    max-height: 100vh !important;
    box-shadow: none !important;
    margin: 0 !important;
  }

  &Backdrop {
    backdrop-filter: blur(10px);
  }

  &Content {
    height: 100% !important;
    max-height: 100% !important;
  }

  &CloseButton {
    width: fit-content !important;
    position: absolute !important;
    right: 40px !important;
    top: 40px !important;
    z-index: 1000 !important;
    color: $fandic-text !important;
    background-color: $fandic-danger !important;

    &:hover {
      background-color: darken($fandic-danger, 10%) !important;
    }
  }

  &Title {
    position: absolute;
    left: 40px;
    top: 40px;
    z-index: 999;
    background-color: $fandic-primary;
    width: fit-content;
    margin-bottom: 20px;
    padding: 12px 18px;

    font-size: 35px !important;
    font-weight: 700 !important;
    font-family: "Roboto Mono", sans-serif !important;
    color: $fandic-text;
  }

  &Timeline {
    &Heading {
      font-size: 30px !important;
      font-weight: 700 !important;
      font-family: "Roboto Mono", sans-serif !important;

      color: $fandic-text;
    }

    &Subtitle {
      font-size: 20px !important;
      font-weight: normal !important;
      font-family: "Inconsolata", "sans-serif" !important;

      color: $fandic-text;
    }

    &Dot {
      font-size: 3rem !important; // Increase the size of the dots
    }

    &Image {
      margin: 30px !important;
      width: 80% !important;
      height: auto !important;
      object-fit: cover !important;
    }
  }

  &Content {
    max-height: 75vh; // Set the maximum height for the content
    overflow-y: auto; // Enable vertical scrolling

    // Custom scrollbar styles
    &::-webkit-scrollbar {
      width: 8px; // Width of the scrollbar
    }

    &::-webkit-scrollbar-track {
      background: transparent; // Background of the scrollbar track
    }

    &::-webkit-scrollbar-thumb {
      background-color: darkgray; // Color of the scrollbar thumb
      border-radius: 10px; // Rounded corners
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: $fandic-primary;// Color of the scrollbar thumb on hover
    }
  }
}
