@import "../../resources/css/colors";
@import "../../resources/css/breakpoints";


.galleryContainerMobile {
  position: relative;
  padding: 0;

  &Arrow {
    position: absolute !important;
    z-index: 999 !important;
    color: $fandic-text !important;
    top: 50%; // Vertikal zentrieren
    transform: translateY(-50%) !important; // Vertikal zentrieren

    &Left {
      left: 0px !important;
    }

    &Right {
      right: 0px !important;
    }
  }

  &Item {
    padding: 0;
    height: fit-content;
  }

  .galleryGrid {
    position: relative;
    background-color: $fandic-background;

    &Spacer {
      height: 0;
      padding-bottom: 25px;
    }

    &ImageContainer {
      position: relative;
      width: 100%;

      &Image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &Title {
        position: absolute;
        left: 20px;
        bottom: -20px;
        padding: 12px 20px;
        font-size: 25px !important;
        font-weight: 700 !important;
        font-family: "Roboto Mono", sans-serif !important;
        width: fit-content;

        color: $fandic-text;
        background-color: $fandic-primary;
      }
    }

    &TextContainer {
      padding: 55px 40px 40px;

      &Text {
        font-size: 16px !important;
        font-weight: normal !important;
        font-family: "Inconsolata", "sans-serif" !important;

        color: $fandic-text;
      }

      &Button {
        display: block;
        margin: 0 auto;
        margin-top: 40px;
        color: $fandic-text;
        background-color: $fandic-secondary;

        &:hover {
          background-color: lighten($fandic-secondary, 10%);
        }
      }
    }
  }
}