@import "../../resources/css/colors";
@import "../../resources/css/breakpoints";

.settingsPageDesktop {
  margin-top: 50px !important;
  margin-bottom: 50px !important;

  &Container {
    background-color: $fandic-background !important;
    padding: 20px !important;
  }
}