.eventsContainerMobile {
  padding: 0;
}
.eventsContainerMobileItem {
  padding: 0;
  height: fit-content;
}
.eventsContainerMobile .eventsGrid {
  position: relative;
  background-color: #0C090A;
}
.eventsContainerMobile .eventsGridImageContainer {
  position: relative;
  width: 100%;
}
.eventsContainerMobile .eventsGridImageContainerImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.eventsContainerMobile .eventsGridImageContainerTitle {
  position: absolute;
  left: 20px;
  bottom: -20px;
  padding: 12px 20px;
  font-size: 25px !important;
  font-weight: 700 !important;
  font-family: "Roboto Mono", sans-serif !important;
  width: fit-content;
  color: white;
  background-color: #EA5F9D;
}
.eventsContainerMobile .eventsGrid .eventsGridEventsContainer .eventList {
  padding: 20px 0 !important;
  margin-top: 25px !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
}
.eventsContainerMobile .eventsGrid .eventsGridEventsContainer .eventList .eventListItem {
  position: relative;
}
.eventsContainerMobile .eventsGrid .eventsGridEventsContainer .eventList .eventListItem .eventListItemButton .eventListItemTextPrimary {
  font-size: 18px !important;
  font-weight: normal !important;
  font-family: "Inconsolata", "sans-serif" !important;
  color: white !important;
  max-width: 40% !important;
}
.eventsContainerMobile .eventsGrid .eventsGridEventsContainer .eventList .eventListItem .eventListItemButton .eventListItemTextPrimaryEmpty {
  font-size: 18px !important;
  font-weight: normal !important;
  font-family: "Inconsolata", "sans-serif" !important;
  color: white !important;
  max-width: 100% !important;
  text-align: center;
}
.eventsContainerMobile .eventsGrid .eventsGridEventsContainer .eventList .eventListItem .eventListItemButton .eventListItemTextSecondary {
  font-size: 14px !important;
  font-weight: normal !important;
  font-family: "Inconsolata", "sans-serif" !important;
  color: #9e9e9e !important;
}
.eventsContainerMobile .eventsGrid .eventsGridEventsContainer .eventList .eventListItem .eventListItemButton .eventListItemIconContainer {
  position: absolute;
  right: 105px;
  color: white !important;
  display: flex;
  align-items: center;
}
.eventsContainerMobile .eventsGrid .eventsGridEventsContainer .eventList .eventListItem .eventListItemButton .eventListItemIconContainerIcon {
  margin-right: 5px;
}
.eventsContainerMobile .eventsGrid .eventsGridEventsContainer .eventList .eventListItem .eventListItemButton .eventListItemIconContainerText {
  position: absolute;
  left: 30px;
  margin-left: 5px !important;
}

