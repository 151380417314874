.desktopLoginForm {
  background-color: #0C090A !important;
  padding: 40px !important;
}
@media (min-width: 1200px) {
  .desktopLoginForm {
    margin: 20px 150px !important;
  }
}
@media (max-width: 1199px) {
  .desktopLoginForm {
    margin: 20px 20px !important;
  }
}
.desktopLoginFormTitle {
  padding: 12px 20px;
  font-size: 30px !important;
  font-weight: 700 !important;
  font-family: "Roboto Mono", sans-serif !important;
  width: fit-content;
  margin-bottom: 30px !important;
  color: white;
  background-color: #EA5F9D;
}
.desktopLoginFormInput {
  margin-bottom: 20px;
}
.desktopLoginFormInputInput {
  color: white !important;
}
.desktopLoginFormInputInput:before {
  border-bottom: 1px solid lightgray !important;
}
.desktopLoginFormInputInput:after {
  border-bottom: 2px solid white !important;
}
.desktopLoginFormInputLabel {
  color: lightgray !important;
}
.desktopLoginFormInputLabel:focus {
  color: white !important;
}
.desktopLoginFormSubmit {
  background-color: #007DC5 !important;
  color: white !important;
  text-align: right !important;
  display: block !important;
  width: fit-content !important;
  margin-left: auto !important;
  margin-top: 20px !important;
}

.desktopLogoutForm {
  background-color: #0C090A !important;
  padding: 40px !important;
}
@media (min-width: 1200px) {
  .desktopLogoutForm {
    margin: 20px 150px !important;
  }
}
@media (max-width: 1199px) {
  .desktopLogoutForm {
    margin: 20px 20px !important;
  }
}
.desktopLogoutFormTitle {
  margin-top: 45px !important;
  margin-bottom: 10px !important;
  font-size: 25px !important;
  font-weight: 700 !important;
  font-family: "Roboto Mono", sans-serif !important;
  color: white !important;
}
.desktopLogoutFormMessageContainer {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.desktopLogoutFormMessageContainerMessage {
  font-family: Inconsolata, sans-serif !important;
  color: white !important;
  font-size: 16px !important;
  text-align: center !important;
}
.desktopLogoutFormSubmit {
  background-color: #007DC5 !important;
  color: white !important;
  text-align: right !important;
  display: inline-block !important;
  width: fit-content !important;
  margin-left: 10px !important;
  margin-top: 10px !important;
}
.desktopLogoutFormGoToSettings {
  background-color: #007DC5 !important;
  color: white !important;
  display: inline-block !important;
  text-align: right !important;
  width: fit-content !important;
  margin-left: auto !important;
  margin-top: 10px !important;
}
.desktopLogoutFormButtonContainer {
  display: flex !important;
  justify-content: space-between !important;
}

