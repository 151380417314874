@import "../../../resources/css/colors";
@import "../../../resources/css/breakpoints";

.contactSetting {
  &ImageTitle {
    margin-bottom: 25px !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    font-family: "Roboto Mono", sans-serif !important;
    color: $fandic-text !important;
  }

  &ImageForm {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &CurrentImage {
      max-width: 100%;
      max-height: 30vh;
      object-fit: cover;
      margin-bottom: 10px;
    }

    &PreviewText {
      font-size: 14px !important;
      font-weight: 700 !important;
      font-family: "Roboto Mono", sans-serif !important;
      color: $fandic-text-secondary !important;
      margin-bottom: 5px !important;
    }

    &FileUploadButton {
      background-color: $fandic-secondary !important; // Hintergrundfarbe des Buttons
      color: $fandic-text !important; // Textfarbe des Buttons
      display: block;
      margin-top: 20px !important;
      width: 100%;
    }

    &SubmitButton {
      background-color: $fandic-secondary !important; // Hintergrundfarbe des Buttons
      color: $fandic-text !important; // Textfarbe des Buttons
      display: block;
      margin-top: 10px !important;
      margin-bottom: 10px !important;
      width: 100%;
    }
  }

  &Divider {
    margin: 20px 0 !important;
    border-bottom: 0.75px solid $fandic-divider !important;
  }

  &TextTitle {
    margin: 25px 0 !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    font-family: "Roboto Mono", sans-serif !important;
    color: $fandic-text !important;
  }

  &TextForm {

    &TextInput {

      &Props {
        color: $fandic-text !important; // Farbe des eingegebenen Textes

        &:before {
          border-bottom: 1px solid lightgray !important; // Farbe der Linie im nicht fokussierten Zustand
        }

        &:after {
          border-bottom: 2px solid $fandic-text !important; // Farbe der Linie im fokussierten Zustand
        }
      }

      &Label {
        color: $fandic-text-secondary !important; // Farbe des Labels im nicht fokussierten Zustand

        &:focus {
          color: $fandic-text !important; // Farbe des Labels im fokussierten Zustand
        }
      }
    }

    &SubmitButton {
      background-color: $fandic-secondary !important; // Hintergrundfarbe des Buttons
      color: $fandic-text !important; // Textfarbe des Buttons
      display: block;
      margin-top: 20px !important;
      margin-bottom: 10px !important;
      width: 100%;
    }
  }
}