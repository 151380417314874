@import "../resources/css/colors";
@import "../resources/css/breakpoints";

.drawer {
  &Paper {
    background-color: $fandic-background !important;
  }

  &Title {
    margin: 0 auto !important;
    margin-top: 20px !important;
    padding: 10px !important;
    text-align: center !important;
    color: $fandic-text;

    font-size: 25px !important;
    font-weight: 800 !important;
    font-family: "Roboto Mono", sans-serif !important;
  }

  &Text {
    color: $fandic-text;
  }

  &Icon {
    color: $fandic-text !important;
  }
}