@import url('https://fonts.googleapis.com/css2?family=Finger+Paint&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wdth,wght@50..200,200..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wdth,wght@50..200,200..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import "resources/css/_colors.scss";
@import "resources/css/_breakpoints.scss";

body {
  margin: 0;

  min-height: 100vh;
  display: flex;
  flex-direction: column;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.background {
  width: 100%;
  margin: 0;
  padding: 0;

  @media (max-width: $md) {
    background-color: $fandic-background !important;
    background-image: none !important;
  }
  @media (min-width: $md) {
    //background-image: url("resources/img/background.png");
    background-color: $fandic-background-light;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    height: auto;
  }
}
