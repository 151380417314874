.eventDetailDialogPaper {
  background-color: #0C090A !important;
  border-radius: 0 !important;
  overflow: visible !important;
}
.eventDetailDialogTitle {
  position: absolute;
  left: -10px;
  top: -10px;
  background-color: #2387D0;
  width: fit-content;
  margin-bottom: 20px;
  padding: 12px 18px;
  font-size: 20px !important;
  font-weight: 700 !important;
  font-family: "Roboto Mono", sans-serif !important;
  color: white;
}
.eventDetailDialogContent {
  margin-top: 80px;
}
.eventDetailDialog .eventDetailText {
  font-size: 14px !important;
  font-weight: normal !important;
  font-family: "Inconsolata", "sans-serif" !important;
  color: white;
}
.eventDetailDialog .eventDetailLocationTitle {
  font-size: 18px !important;
  font-weight: 700 !important;
  font-family: "Roboto Mono", sans-serif !important;
  color: white;
}
.eventDetailDialog .eventDetailLocationAddress {
  font-size: 14px !important;
  font-weight: normal !important;
  font-family: "Inconsolata", "sans-serif" !important;
  color: white;
}
.eventDetailDialog .eventDetailLocationExportButton {
  color: white;
  background-color: #007DC5;
  border-radius: 1px;
}
.eventDetailDialog .eventDetailLocationExportButton:hover {
  background-color: #009df8;
}

