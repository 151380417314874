.bookingSettingImageTitle {
  margin-bottom: 25px !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  font-family: "Roboto Mono", sans-serif !important;
  color: white !important;
}
.bookingSettingImageForm {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.bookingSettingImageFormCurrentImage {
  max-width: 100%;
  max-height: 30vh;
  object-fit: cover;
  margin-bottom: 10px;
}
.bookingSettingImageFormPreviewText {
  font-size: 14px !important;
  font-weight: 700 !important;
  font-family: "Roboto Mono", sans-serif !important;
  color: #9e9e9e !important;
  margin-bottom: 5px !important;
}
.bookingSettingImageFormFileUploadButton {
  background-color: #007DC5 !important;
  color: white !important;
  display: block;
  margin-top: 20px !important;
  width: 100%;
}
.bookingSettingImageFormSubmitButton {
  background-color: #007DC5 !important;
  color: white !important;
  display: block;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  width: 100%;
}
.bookingSettingDivider {
  margin: 20px 0 !important;
  border-bottom: 0.75px solid white !important;
}
.bookingSettingTextTitle {
  margin: 25px 0 !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  font-family: "Roboto Mono", sans-serif !important;
  color: white !important;
}
.bookingSettingTextFormTextInputProps {
  color: white !important;
}
.bookingSettingTextFormTextInputProps:before {
  border-bottom: 1px solid lightgray !important;
}
.bookingSettingTextFormTextInputProps:after {
  border-bottom: 2px solid white !important;
}
.bookingSettingTextFormTextInputLabel {
  color: #9e9e9e !important;
}
.bookingSettingTextFormTextInputLabel:focus {
  color: white !important;
}
.bookingSettingTextFormSubmitButton {
  background-color: #007DC5 !important;
  color: white !important;
  display: block;
  margin-top: 20px !important;
  margin-bottom: 10px !important;
  width: 100%;
}

