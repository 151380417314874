.aboutMeSecretContainerMobile {
  padding: 0;
}
.aboutMeSecretContainerMobileItem {
  padding: 0;
  height: fit-content;
}
.aboutMeSecretContainerMobile .aboutMeSecretGrid {
  overflow: hidden;
  position: relative;
  background-color: #0C090A;
}
.aboutMeSecretContainerMobile .aboutMeSecretGridImageContainer {
  position: relative;
  width: 100%;
}
.aboutMeSecretContainerMobile .aboutMeSecretGridImageContainerImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.aboutMeSecretContainerMobile .aboutMeSecretGridImageContainerTitle {
  position: absolute;
  left: 20px;
  bottom: -20px;
  padding: 12px 20px;
  font-size: 25px !important;
  font-weight: 700 !important;
  font-family: "Roboto Mono", sans-serif !important;
  width: fit-content;
  color: white;
  background-color: #EA5F9D;
}
.aboutMeSecretContainerMobile .aboutMeSecretGrid .aboutMeSecretTimeline {
  padding-top: 75px;
  padding-bottom: 15px;
  background-color: #0C090A;
}
.aboutMeSecretContainerMobile .aboutMeSecretGrid .aboutMeSecretTimelineHeading {
  font-size: 20px !important;
  color: white;
}
.aboutMeSecretContainerMobile .aboutMeSecretGrid .aboutMeSecretTimelineSubtitle {
  font-size: 16px !important;
  color: white;
}

