@import "../resources/css/colors";
@import "../resources/css/breakpoints";

.onepage {
  &Grid {
    @media(max-width: $sd) {
      padding: 20px;
    }

    @media(min-width: $sd) {
      padding: 40px;
    }

    &Item {
      margin-top: 20px !important;
      margin-bottom: 20px !important;
    }
  }
}