.drawerPaper {
  background-color: #0C090A !important;
}
.drawerTitle {
  margin: 0 auto !important;
  margin-top: 20px !important;
  padding: 10px !important;
  text-align: center !important;
  color: white;
  font-size: 25px !important;
  font-weight: 800 !important;
  font-family: "Roboto Mono", sans-serif !important;
}
.drawerText {
  color: white;
}
.drawerIcon {
  color: white !important;
}

