@import '../resources/css/colors';
@import '../resources/css/breakpoints';

.Base {
  &-header {
    position: fixed;
    top: 0;

    background-color: $fandic-background;
    display: flex;

    align-items: center;
    width: 100%;

    z-index: 1000;

    @media(min-width: $xs) {
      height: 75px;
      justify-content: left;
    }

    @media(min-width: $sm) {
      height: 90px;
      justify-content: center;
    }
  }

  &-logo {
    @media(min-width: $xs) {
      height: 58px;
    }
    @media(min-width: $sm) {
      height: 70px;
    }

    &:hover {
        cursor: pointer;
    }
  }
}

.grecaptcha-badge { visibility: hidden; }