@import "../../resources/css/colors";
@import "../../resources/css/breakpoints";

.loginBox {

  margin: 80px 40px 40px;

  &Title {
    margin-top: 45px;
    margin-bottom: 10px;
    font-size: 25px !important;
    font-weight: 700 !important;
    font-family: "Roboto Mono", sans-serif !important;
    color: $fandic-text !important;
  }

  .loginForm {

    &Title {
      margin-top: 45px;
      margin-bottom: 10px;
      font-size: 25px !important;
      font-weight: 700 !important;
      font-family: "Roboto Mono", sans-serif !important;
      color: $fandic-text !important;
    }

    &MessageInfo {
      margin-bottom: 10px;

      &Confirm {
        color: #4dda4d !important;
        font-size: 14px !important;
      }

      &Error {
        color: #a91919 !important;
        font-size: 14px !important;
      }
    }

    &Input {
      margin-bottom: 20px;

      &Input {
        color: white !important; // Farbe des eingegebenen Textes

        &:before {
          border-bottom: 1px solid lightgray !important; // Farbe der Linie im nicht fokussierten Zustand
        }

        &:after {
          border-bottom: 2px solid white !important; // Farbe der Linie im fokussierten Zustand
        }
      }

      &Label {
        color: lightgray !important; // Farbe des Labels im nicht fokussierten Zustand

        &:focus {
          color: white !important; // Farbe des Labels im fokussierten Zustand
        }
      }
    }

    &Submit {
      background-color: $fandic-secondary !important; // Hintergrundfarbe des Buttons
      color: $fandic-text !important; // Textfarbe des Buttons
      text-align: right;
      display: block;
      width: fit-content;
      margin-left: auto;
      margin-top: 10px;
    }
  }

  .logoutForm {

    &Title {
        margin-top: 45px;
        margin-bottom: 10px;
        font-size: 25px !important;
        font-weight: 700 !important;
        font-family: "Roboto Mono", sans-serif !important;
        color: $fandic-text !important;
    }

    &MessageContainer {
      margin-top: 20px;
      margin-bottom: 20px;

      &Message {
        font-family: Inconsolata, sans-serif !important;
        color: $fandic-text !important;
        font-size: 14px !important;
      }
    }

    &Submit {
      background-color: $fandic-secondary !important; // Hintergrundfarbe des Buttons
      color: $fandic-text !important; // Textfarbe des Buttons
      text-align: right !important;
      display: inline-block !important;
      width: fit-content !important;
      margin-left: 10px !important;
      margin-top: 10px !important;
    }

    &GoToSettings {
      background-color: $fandic-secondary !important; // Hintergrundfarbe des Buttons
      color: $fandic-text !important; // Textfarbe des Buttons
      text-align: right !important;
      display: inline-block !important;
      width: fit-content !important;
      margin-left: auto !important;
      margin-top: 10px !important;
    }

    &ButtonContainer {
      display: flex !important;
      justify-content: space-between !important; // Verwenden Sie space-around oder space-evenly für unterschiedliche Abstände
    }
  }
}