.milestoneImageDialog {
  width: 100%;
  margin: 0;
  padding: 0;
}
.milestoneImageDialogPaper {
  background-color: transparent !important;
  border-radius: 0 !important;
  margin: 15px !important;
}
.milestoneImageDialogContent {
  margin: 0 !important;
  padding: 0 !important;
}
.milestoneImageDialogImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

