.bookingContainerMobile {
  padding: 0;
}
.bookingContainerMobileItem {
  padding: 0;
  height: fit-content;
}
.bookingContainerMobile .bookingGrid {
  position: relative;
  background-color: #0C090A;
}
.bookingContainerMobile .bookingGridImageContainer {
  position: relative;
  width: 100%;
}
.bookingContainerMobile .bookingGridImageContainerImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.bookingContainerMobile .bookingGridImageContainerTitle {
  position: absolute;
  left: 20px;
  bottom: -20px;
  padding: 12px 20px;
  font-size: 25px !important;
  font-weight: 700 !important;
  font-family: "Roboto Mono", sans-serif !important;
  width: fit-content;
  color: white;
  background-color: #EA5F9D;
}
.bookingContainerMobile .bookingGridTextContainer {
  padding: 45px 40px 40px;
}
.bookingContainerMobile .bookingGridTextContainerText {
  font-size: 16px !important;
  font-weight: normal !important;
  font-family: "Inconsolata", "sans-serif" !important;
  color: white;
}
.bookingContainerMobile .bookingGridTextContainer .bookingFormTitle {
  margin-top: 45px;
  margin-bottom: 10px;
  font-size: 25px !important;
  font-weight: 700 !important;
  font-family: "Roboto Mono", sans-serif !important;
  color: white !important;
}
.bookingContainerMobile .bookingGridTextContainer .bookingFormMessageInfoConfirm {
  color: #4dda4d !important;
  font-size: 14px !important;
}
.bookingContainerMobile .bookingGridTextContainer .bookingFormMessageInfoError {
  color: #a91919 !important;
  font-size: 14px !important;
}
.bookingContainerMobile .bookingGridTextContainer .bookingFormInput {
  margin-bottom: 20px;
}
.bookingContainerMobile .bookingGridTextContainer .bookingFormInput .bookingInput {
  color: white !important;
}
.bookingContainerMobile .bookingGridTextContainer .bookingFormInput .bookingInput:before {
  border-bottom: 1px solid lightgray !important;
}
.bookingContainerMobile .bookingGridTextContainer .bookingFormInput .bookingInput:after {
  border-bottom: 2px solid white !important;
}
.bookingContainerMobile .bookingGridTextContainer .bookingFormInput .bookingInputLabel {
  color: lightgray !important;
}
.bookingContainerMobile .bookingGridTextContainer .bookingFormInput .bookingInputLabel:focus {
  color: white !important;
}
.bookingContainerMobile .bookingGridTextContainer .bookingFormSubmit {
  background-color: #007DC5 !important;
  color: white !important;
  text-align: right;
  display: block;
  width: fit-content;
  margin-left: auto;
  margin-top: 10px;
}

