@import "../../resources/css/colors";
@import "../../resources/css/breakpoints";

.eventsSection {
  position: relative;
  background-color: $fandic-background;
  margin-top: 40px;
  box-shadow: black 0px 0px 15px 0px !important;

  &Title {
    position: absolute;

    left: -50px;
    top: -40px;
    padding: 12px 20px;
    font-size: 30px !important;
    font-weight: 700 !important;
    font-family: "Roboto Mono", sans-serif !important;
    width: fit-content;


    color: $fandic-text;
    background-color: $fandic-primary;
  }

  &List {
    padding: 20px !important;
  }
}