@import "../../resources/css/colors";
@import "../../resources/css/breakpoints";

.onepageEventDetailDialog {
  &Paper {
    background-color: $fandic-background !important;
    border-radius: 0 !important;
    overflow: visible !important;
    width: 30% !important;
  }

  &Title {
    position: absolute;
    left: -20px;
    top: -20px;
    background-color: $fandic-secondary-light;
    width: fit-content;
    margin-bottom: 20px;
    padding: 12px 18px;

    font-size: 25px !important;
    font-weight: 700 !important;
    font-family: "Roboto Mono", sans-serif !important;
    color: $fandic-text;
  }

  &Content {
    margin-top: 80px;
    padding-top: 5px !important;
    padding-bottom: 40px !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .eventDetailText {
    margin-left: 20px !important;
    font-size: 16px !important;
    font-weight: normal !important;
    font-family: "Inconsolata", "sans-serif" !important;

    color: $fandic-text;
  }

  .eventDetailLocation {
    &Title {
      font-size: 20px !important;
      font-weight: 700 !important;
      font-family: "Roboto Mono", sans-serif !important;

      color: $fandic-text;
    }

    &Address {
      font-size: 16px !important;
      font-weight: normal !important;
      font-family: "Inconsolata", "sans-serif" !important;

      color: $fandic-text;

      p {
        margin-left: 20px !important;
      }
    }


    &ExportButton {
      color: $fandic-text;
      background-color: $fandic-secondary;
      border-radius: 1px;
      font-size: 16px !important;
      height: 50px !important;

      &:hover {
        background-color: lighten($fandic-secondary, 10%);
      }
    }

  }
}