.settingsBox {
  padding: 20px;
}
.settingsBoxTitle {
  margin: 0 15px 15px !important;
  font-size: 25px !important;
  font-weight: 700 !important;
  font-family: "Roboto Mono", sans-serif !important;
  color: white !important;
}
.settingsBoxAccordion {
  color: white !important;
  background-color: #1A1A1A !important;
}
.settingsBoxAccordionSummaryIcon {
  color: white !important;
}

