@import "../../resources/css/colors";
@import "../../resources/css/breakpoints";

.grecaptcha-badge {
  display: none;
}

.MobileCaptchaGrid {
  margin-top: 20px;

  &Item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &Message {
      color: $fandic-text-secondary;
      font-size: 10px;
      text-align: center !important;

      &Link {
        color: $fandic-text-link;
        text-decoration: none;
      }
    }
  }
}