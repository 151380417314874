@import "../resources/css/colors";
@import "../resources/css/breakpoints";

.Footer {
  position: relative;
  width: 100%;
  background-color: $fandic-background;

  display: flex;
  align-items: center;

  padding-top: 20px;
  padding-bottom: 15px;

  .GridContainerFooter {
    padding-left: 10px;
    padding-right: 10px;
    align-items: center;
  }

  .GridItemFooter {
    display: flex;
    justify-content: center;
    align-items: center;

    &:first-child {
      @media(min-width: $xs) {
        padding-top: 20px;
      }

      @media(min-width: $sm) {
        padding-top: 0;
      }
    }

    @media(min-width: $xs) {
      padding-bottom: 30px;
    }

    @media(min-width: $sm) {
      padding-bottom: 15px;
    }

    @media(min-width: $md) {
      padding-bottom: 0;
    }

    &Icon {

      &:hover {
        cursor: pointer;
      }
    }
  }

  &Logo {
    opacity: 0.4;
    margin: 0 auto;

    @media(min-width: $xs) {
      height: 70px;
    }

    @media(min-width: $sm) {
      height: 50px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &Text {
    color: $fandic-text-secondary !important;
    font-size: 16px !important;
  }
}

.IconInstagram {
  margin-right: 7.5px;
  margin-left: 7.5px;
  color: $instagram;
  scale: 0.9;

}

.IconFacebook {
  margin-right: 7.5px;
  margin-left: 7.5px;
  color: $facebook;
  scale: 0.9;
}

.IconSoundcloud {
  margin-right: 7.5px;
  margin-left: 7.5px;
  color: $soundcloud;
  scale: 0.9;
}

.IconTwitch {
  margin-right: 7.5px;
  margin-left: 7.5px;
  color: $twitch;
  scale: 0.9;
}

.IconArrowRight {
  margin-right: 7.5px;
  margin-left: 7.5px;
  color: $fandic-text-secondary !important;
  font-size: 30px !important;
}