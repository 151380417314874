.eventCalendarSettingsEventListItem {
  position: relative;
  padding: 0 !important;
}
.eventCalendarSettingsEventListItemButtonTextPrimary {
  font-size: 18px !important;
  font-weight: normal !important;
  font-family: "Inconsolata", "sans-serif" !important;
  color: white !important;
  max-width: 40% !important;
}
.eventCalendarSettingsEventListItemButtonTextPrimaryEmpty {
  font-size: 18px !important;
  font-weight: normal !important;
  font-family: "Inconsolata", "sans-serif" !important;
  color: white !important;
  max-width: 100% !important;
  text-align: center;
}
.eventCalendarSettingsEventListItemButtonTextSecondary {
  font-size: 14px !important;
  font-weight: normal !important;
  font-family: "Inconsolata", "sans-serif" !important;
  color: #9e9e9e !important;
}
.eventCalendarSettingsEventListItemButtonIconContainer {
  position: absolute;
  right: 105px;
  color: white !important;
  display: flex;
  align-items: center;
}
.eventCalendarSettingsEventListItemButtonIconContainerIcon {
  margin-right: 5px;
}
.eventCalendarSettingsEventListItemButtonIconContainerText {
  position: absolute;
  left: 30px;
  margin-left: 5px !important;
}

