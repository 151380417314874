@import "../../resources/css/colors";
@import "../../resources/css/breakpoints";

.Base-header {
  &-landing {
    visibility: hidden;
    height: 0;
  }

  &-anim {
    animation: slideDownFadeIn 2s ease-out;
  }
}

.landingPage {
  background-color: $fandic-background !important;
  position: absolute;
    top: 0;
    left: 0;

  &Video {
    @media (max-width: $xl) {
      width: 100vw;
      height: 100vh;
    }
    width: 100vw;
    max-height: 99vh;
    object-fit: cover;
    overflow: hidden;

    &Anim {
      animation: BlurFadeOver 3s ease-in-out;
    }
  }

  &Container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &Anim {
      animation: FadeIn 2s ease-out;
    }

    &Hide {
      display: none;
    }

    &Button {
      font-size: 25px !important;
      background-color: $fandic-primary !important;

      @media(max-width: $md) {
        width: 50% !important;
      }

      @media(min-width: $md) {
        width: 30% !important;
      }

      @media (min-width: $xl) {
        width: 20% !important;
      }
    }

    &Button:hover {
      background-color: $fandic-primary-hover !important;
    }

    &Text {
        color: $fandic-text !important;
        font-size: 20px !important;
        margin-top: 50px !important;
    }
  }
}

@keyframes slideDownFadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes FadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes BlurFadeOver {
  0% {
    opacity: 1;
  }

  66% {
    filter: blur(10px);
    opacity: 0;
  }

  100% {
      filter: blur(0px);
      opacity: 1;
  }
}