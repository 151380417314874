@import "../../resources/css/colors";
@import "../../resources/css/breakpoints";

.contactForm {
  background-color: $fandic-background !important;
  padding: 40px !important;

  @media (min-width: $lg) {
    margin: 20px 150px !important;
  }

  @media (max-width: $lg - 1px) {
    margin: 20px 20px !important;
  }

  &Title {
    padding: 12px 20px;
    font-size: 30px !important;
    font-weight: 700 !important;
    font-family: "Roboto Mono", sans-serif !important;
    width: fit-content;
    margin-bottom: 30px !important;

    color: $fandic-text;
    background-color: $fandic-primary;
  }

  &Text {
    font-size: 16px !important;
    font-weight: normal !important;
    font-family: "Inconsolata", "sans-serif" !important;
    margin-bottom: 20px !important;

    color: $fandic-text;
  }

  &SubTitle {
    margin-top: 45px;
    margin-bottom: 10px;
    font-size: 25px !important;
    font-weight: 700 !important;
    font-family: "Roboto Mono", sans-serif !important;
    color: $fandic-text !important;
  }

  &Input {
    margin-bottom: 20px;

    .contactInput {
      color: white !important; // Farbe des eingegebenen Textes

      &:before {
        border-bottom: 1px solid lightgray !important; // Farbe der Linie im nicht fokussierten Zustand
      }

      &:after {
        border-bottom: 2px solid white !important; // Farbe der Linie im fokussierten Zustand
      }
    }

    .contactInputLabel {
      color: lightgray !important; // Farbe des Labels im nicht fokussierten Zustand

      &:focus {
        color: white !important; // Farbe des Labels im fokussierten Zustand
      }
    }
  }

  &Submit {
    background-color: $fandic-secondary !important; // Hintergrundfarbe des Buttons
    color: $fandic-text !important; // Textfarbe des Buttons
    text-align: right !important;
    display: block !important;
    width: fit-content !important;
    margin-left: auto !important;
    margin-top: 20px !important;
  }
}