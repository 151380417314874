@import "../../resources/css/colors";
@import "../../resources/css/breakpoints";

.imprintPageMobile {
  background-color: $fandic-background !important;
  padding: 80px !important;
  margin: 20px !important;

  &Title {
    margin-left: 15px !important;
    margin-top: 15px !important;
    padding: 12px 20px;
    font-size: 25px !important;
    font-weight: 700 !important;
    font-family: "Roboto Mono", sans-serif !important;
    width: fit-content;

    color: $fandic-text;
    background-color: $fandic-primary;
  }

  &SubTitle {
    padding: 10px 18px;
    margin-bottom: 30px !important;
    font-size: 20px !important;
    font-weight: 700 !important;
    font-family: "Roboto Mono", sans-serif !important;
    color: $fandic-text !important;
    width: fit-content;
    background-color: $fandic-secondary;
  }

  &SubSubTitle {
    margin-bottom: 20px !important;
    font-size: 15px !important;
    font-weight: 700 !important;
    font-family: "Roboto Mono", sans-serif !important;
    color: $fandic-text !important;
  }

  &Text {
    margin-left: 25px !important;
    margin-right: 25px !important;
    font-size: 14px !important;
    font-weight: normal !important;
    font-family: "Inconsolata", "sans-serif" !important;
    margin-bottom: 20px !important;

    color: $fandic-text !important;

    &Secondary {
      font-size: 16px !important;
      font-weight: normal !important;
      font-family: "Inconsolata", "sans-serif" !important;
      margin-bottom: 20px !important;
      color: $fandic-text-secondary !important;
    }
  }

  &ImprintContainer {
    padding-top: 30px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  &DSGVOContainer {
    padding-left: 30px !important;
    padding-right: 30px !important;

    h2 {
      margin-top: 80px;
      padding: 10px 18px;
      font-size: 20px !important;
      font-weight: 700 !important;
      font-family: "Roboto Mono", sans-serif !important;
      color: $fandic-text !important;
      width: fit-content;
      background-color: $fandic-secondary;
    }

    h3 {
      font-size: 16px !important;
      font-weight: 700 !important;
      font-family: "Roboto Mono", sans-serif !important;
      color: $fandic-text !important;
    }

    h4 {
      margin-left: 5px !important;
      font-size: 14px !important;
      font-weight: 700 !important;
      font-family: "Roboto Mono", sans-serif !important;
      color: $fandic-text !important;
    }

    p, li {
      margin-left: 25px !important;
      margin-right: 25px !important;
      font-family: "Inconsolata", "sans-serif" !important;
      font-size: 14px !important;
      color: $fandic-text !important;
    }

    a {
      font-family: "Inconsolata", "sans-serif" !important;
      color: $fandic-secondary !important;
      font-size: 14px !important;
      text-decoration: none;
      word-wrap: break-word; /* Allows long words to be broken and wrap onto the next line */
      overflow-wrap: break-word; /* Ensures that the content will wrap within the container */
      white-space: normal;

    }
  }
}