@media (max-width: 750px) {
  .main-content {
    min-height: 100vh;
  }
}
@media (max-width: 900px) {
  .main-content {
    min-height: calc(100vh - 147px);
  }
}
@media (min-width: 900px) {
  .main-content {
    min-height: calc(100vh - 85px);
  }
}

