@import "../../resources/css/colors";
@import "../../resources/css/breakpoints";

.eventsContainerMobile {
  padding: 0;

  &Item {
    padding: 0;
    height: fit-content;
  }

  .eventsGrid {
    position: relative;
    background-color: $fandic-background;
    //box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4); // Schatten hinzugefügt

    &ImageContainer {
      position: relative; // Hinzugefügt
      width: 100%;

      &Image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &Title {
        position: absolute;
        left: 20px;
        bottom: -20px;
        padding: 12px 20px;
        font-size: 25px !important;
        font-weight: 700 !important;
        font-family: "Roboto Mono", sans-serif !important;
        width: fit-content;

        color: $fandic-text;
        background-color: $fandic-primary;
      }
    }

    .eventsGridEventsContainer {
      .eventList {
        padding: 20px 0 !important;
        margin-top: 25px !important;
        margin-left: 10px !important;
        margin-right: 10px !important;

        .eventListItem {
          position: relative; // Hinzugefügt
          .eventListItemButton {
            .eventListItemTextPrimary {
              font-size: 18px !important;
              font-weight: normal !important;
              font-family: "Inconsolata", "sans-serif" !important;

              color: $fandic-text !important;
              max-width: 40% !important;

              &Empty {
                font-size: 18px !important;
                font-weight: normal !important;
                font-family: "Inconsolata", "sans-serif" !important;

                color: $fandic-text !important;
                max-width: 100% !important;
                text-align: center;
              }
            }

            .eventListItemTextSecondary {
              font-size: 14px !important;
              font-weight: normal !important;
              font-family: "Inconsolata", "sans-serif" !important;

              color: $fandic-text-secondary !important;
            }

            .eventListItemIconContainer {
              position: absolute; // Geändert
              right: 105px; // Geändert
              color: $fandic-text !important;
              display: flex; // Hinzugefügt
              align-items: center; // Hinzugefügt

              &Icon {
                margin-right: 5px;
              }

              &Text {
                position: absolute; // Hinzugefügt
                left: 30px; // Hinzugefügt
                margin-left: 5px !important;
              }
            }
          }
        }
      }
    }
  }
}