@import "../../resources/css/colors";
@import "../../resources/css/breakpoints";

.eventDetailDialog {
  &Paper {
    background-color: $fandic-background !important;
    border-radius: 0 !important;
    overflow: visible !important;
  }

  &Title {
    position: absolute;
    left: -10px;
    top: -10px;
    background-color: $fandic-secondary-light;
    width: fit-content;
    margin-bottom: 20px;
    padding: 12px 18px;

    font-size: 20px !important;
    font-weight: 700 !important;
    font-family: "Roboto Mono", sans-serif !important;
    color: $fandic-text;
  }

  &Content {
    margin-top: 80px;
  }

  .eventDetailText {
    font-size: 14px !important;
    font-weight: normal !important;
    font-family: "Inconsolata", "sans-serif" !important;

    color: $fandic-text;
  }

  .eventDetailLocation {
    &Title {
        font-size: 18px !important;
        font-weight: 700 !important;
        font-family: "Roboto Mono", sans-serif !important;

        color: $fandic-text;
    }

    &Address {
        font-size: 14px !important;
        font-weight: normal !important;
        font-family: "Inconsolata", "sans-serif" !important;

        color: $fandic-text;
    }


    &ExportButton {
      color: $fandic-text;
      background-color: $fandic-secondary;
      border-radius: 1px;

      &:hover {
        background-color: lighten($fandic-secondary, 10%);
      }
    }

  }
}