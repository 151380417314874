.milestoneListItemButton {
  width: 100% !important;
}
.milestoneListItemButtonTitle {
  font-size: 18px !important;
  font-weight: 600 !important;
  font-family: "Roboto Mono", sans-serif !important;
  color: white !important;
  margin-right: 15px !important;
}
.milestoneListItemButtonIconContainerImage {
  max-width: 25vw;
  max-height: 10vh;
}
.milestoneListItemButtonText {
  margin-left: 20px !important;
}
.milestoneListItemButtonTextPrimary {
  font-size: 18px !important;
  font-weight: 500 !important;
  font-family: "Roboto", sans-serif !important;
  color: white !important;
}
.milestoneListItemButtonTextSecondary {
  font-size: 14px !important;
  font-family: "Roboto", sans-serif !important;
  color: white !important;
}
.milestoneListItemPositionInput {
  width: 50px !important;
  margin-left: 10px !important;
}

