@import "../../resources/css/colors";
@import "../../resources/css/breakpoints";

.gallerySection {
  position: relative;

  &TitleContainer {
    position: relative;

    &Title {
      position: absolute;
      z-index: 2;
      top: -30px;
      padding: 12px 20px;
      font-size: 30px !important;
      font-weight: 700 !important;
      font-family: "Roboto Mono", sans-serif !important;
      width: fit-content;

      @media (min-width: $sm) {
        left: -5px !important;
      }
      
      @media (min-width: $md) {
        left: 35px !important;
      }


      color: $fandic-text;
      background-color: $fandic-primary;
    }
  }

  &Arrow {
    position: absolute !important;
    z-index: 999 !important;
    color: $fandic-text !important;

    @media (min-width: $sm) {
      min-width: 0 !important;
      width: 40px !important;
      background: rgba(red($fandic-divier-secondary), green($fandic-divier-secondary), blue($fandic-divier-secondary), 0.5) !important;
      top: 88% !important;

      & svg {
        font-size: 15px !important; // Ändern Sie diesen Wert, um die Größe der Icons anzupassen
      }

      &:hover {
        color: $fandic-white !important;
        background-color: $fandic-primary !important;
      }
    }

    @media (min-width: $md) {
      top: 41% !important;
      background: rgba(red($fandic-divier-secondary), green($fandic-divier-secondary), blue($fandic-divier-secondary), 0.5) !important;

      &:hover {
        color: $fandic-white !important;
        background-color: $fandic-secondary !important;
      }
    }

    @media (min-width: $lg) {
      top: 43% !important;
      background: rgba(red($fandic-divier-secondary), green($fandic-divier-secondary), blue($fandic-divier-secondary), 0.5) !important;

      &:hover {
        color: $fandic-white !important;
        background-color: $fandic-secondary !important;
      }
    }

    @media (min-width: $xl) {
      min-width: 0 !important;
      width: 50px !important;
      top: 44% !important;
      background: rgba(red($fandic-divier-secondary), green($fandic-divier-secondary), blue($fandic-divier-secondary), 0.5) !important;

      &:hover {
        color: $fandic-white !important;
        background-color: $fandic-secondary !important;
      }

      & svg {
        font-size: 20px !important; // Ändern Sie diesen Wert, um die Größe der Icons anzupassen
      }
    }

    &Left {
      @media (min-width: $sm) {
        left: 10px !important;
      }

      @media (min-width: $md) {
        left: 30px !important;
      }

      @media (min-width: $xl) {
        left: 50px !important;
      }
    }

    &Right {
      @media (min-width: $sm) {
        right: 10px !important;
      }

      @media (min-width: $md) {
        right: 30px !important;
      }

      @media (min-width: $xl) {
        right: 50px !important;
      }
    }
  }

  &ImageGrid {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    margin-bottom: 10px !important;

    &ItemLeft {
      width: 60%;

      &Image {
        width: 100%;
        height: auto;
        object-fit: cover;
        box-shadow: black 0px 0px 15px 0px !important;


        @media (min-width: $sm) {
          max-height: 23vh;
        }

        @media (min-width: $sd) {
          max-height: 25vh;
        }

        @media (min-width: $md) {
          max-height: 32vh;
        }

        @media (min-width: $lg) {
          max-height: 39vh;
        }

        @media (min-width: $xl) {
          max-height: 42vh;
        }

        @media (min-width: $xxl) {
          max-height: 45vh;
        }
      }
    }

    &ItemRight {
      background: $fandic-background;
      width: 40% !important;
      max-width: 40% !important;

      box-shadow: black 0px 0px 15px 0px !important;

      @media (min-width: $sm) {
        max-height: 23vh;
        margin-left: 20px !important;
      }

      @media (min-width: $sd) {
        max-height: 25vh;
        margin-left: 20px !important;
      }

      @media (min-width: $md) {
        max-height: 32vh;
        margin-left: 0 !important;
      }

      @media (min-width: $lg) {
        max-height: 39vh;
      }

      @media (min-width: $xl) {
        max-height: 42vh;
      }

      @media (min-width: $xxl) {
        max-height: 45vh;
      }

      &Title {

        @media (min-width: $sm) {
            font-size: 25px !important;
            margin: 5vw auto 0 auto !important;
        }

        @media (min-width: $sd) {
          font-size: 28px !important;
          margin: 5vw auto 0 auto !important;
        }

        @media (min-width: $md) {
          font-size: 30px !important;
          margin: 5vw auto 0 auto !important;
        }

        @media (min-width: $lg) {
          font-size: 40px !important;
          margin: 5vw auto 0 auto !important;
        }

        font-weight: 700 !important;
        font-family: "Roboto Mono", sans-serif !important;
        width: fit-content;

        color: $fandic-text;
      }

      &Text {
        max-width: 100% !important;
        word-wrap: break-word !important;
        overflow-wrap: break-word !important;
        white-space: normal !important;
        padding: 0 20px !important;

        @media (min-width: $sm) {
          font-size: 16px !important;
          margin: 2vw auto 0 auto !important;
          text-align: center !important;
        }

        @media (min-width: $sd) {
          font-size: 16px !important;
          margin: 2vw auto 0 auto !important;
        }

        @media (min-width: $md) {
          font-size: 16px !important;
          margin: 10px auto 0 auto !important;
        }

        @media (min-width: $lg) {
          font-size: 16px !important;
          margin: 10px auto 0 auto !important;
        }

        font-weight: normal !important;
        font-family: "Inconsolata", "sans-serif" !important;
        width: max-content !important;

        color: $fandic-text;
      }
    }
  }
}

.MuiMobileStepper-root {
  justify-content: center !important; /* Zentriert den MobileStepper */
  background: transparent !important; /* Macht den Hintergrund transparent */
}

.MuiMobileStepper-dot {
  background-color: $fandic-divier-secondary !important;
}

.MuiMobileStepper-dotActive {
  background-color: $fandic-primary !important; /* Färbt die aktiven Schritte mit der Farbe des Titelhintergrunds */
}

.MuiMobileStepper-backButton,
.MuiMobileStepper-nextButton {
  display: none; /* Versteckt die Buttons */
}