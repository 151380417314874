@import "../../../resources/css/colors";
@import "../../../resources/css/breakpoints";

.eventCalendarSettingsEventListItem {
  position: relative; // Hinzugefügt
  padding: 0 !important;

  &Button {
    &TextPrimary {
      font-size: 18px !important;
      font-weight: normal !important;
      font-family: "Inconsolata", "sans-serif" !important;

      color: $fandic-text !important;
      max-width: 40% !important;

      &Empty {
        font-size: 18px !important;
        font-weight: normal !important;
        font-family: "Inconsolata", "sans-serif" !important;

        color: $fandic-text !important;
        max-width: 100% !important;
        text-align: center;
      }
    }

    &TextSecondary {
      font-size: 14px !important;
      font-weight: normal !important;
      font-family: "Inconsolata", "sans-serif" !important;

      color: $fandic-text-secondary !important;
    }

    &IconContainer {
      position: absolute; // Geändert
      right: 105px; // Geändert
      color: $fandic-text !important;
      display: flex; // Hinzugefügt
      align-items: center; // Hinzugefügt

      &Icon {
        margin-right: 5px;
      }

      &Text {
        position: absolute; // Hinzugefügt
        left: 30px; // Hinzugefügt
        margin-left: 5px !important;
      }
    }
  }
}