.grecaptcha-badge {
  display: none;
}

.desktopCaptchaGrid {
  margin-top: 20px;
}
.desktopCaptchaGridItem {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.desktopCaptchaGridItemMessage {
  color: #9e9e9e;
  font-size: 12px;
  text-align: center !important;
}
.desktopCaptchaGridItemMessageLink {
  color: #007dc5;
  text-decoration: none;
}

