@media (min-width: 0px) {
  .HeaderPlaceholder {
    height: 75px;
  }
}
@media (min-width: 600px) {
  .HeaderPlaceholder {
    height: 90px;
  }
}

