@import "../resources/css/_colors.scss";
@import "../resources/css/_breakpoints.scss";

.main-content {
  @media(max-width: $sd) {
    min-height: calc(100vh);
  }
  @media(max-width: $md) {
    min-height: calc(100vh - 147px);
  }
  @media(min-width: $md) {
    min-height: calc(100vh - 85px);
  }
}