@import "../../resources/css/colors";
@import "../../resources/css/breakpoints";

.aboutMeSecretContainerMobile {
  padding: 0;

  &Item {
    padding: 0;
    height: fit-content;
  }

  .aboutMeSecretGrid {
    overflow: hidden;
    position: relative;
    background-color: $fandic-background;

    &ImageContainer {
      position: relative; // Hinzugefügt
      width: 100%;

      &Image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }


      &Title {
        position: absolute;
        left: 20px;
        bottom: -20px;
        padding: 12px 20px;
        font-size: 25px !important;
        font-weight: 700 !important;
        font-family: "Roboto Mono", sans-serif !important;
        width: fit-content;


        color: $fandic-text;
        background-color: $fandic-primary;
      }
    }

    .aboutMeSecretTimeline {
      padding-top: 75px;
      padding-bottom: 15px;
      background-color: $fandic-background;

      &Heading {
        font-size: 20px !important;
        color: $fandic-text;
      }

      &Subtitle {
        font-size: 16px !important;
        color: $fandic-text;
      }

    }
  }

}
