.gallerySettingsTextTitle {
  margin-bottom: 25px !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  font-family: "Roboto Mono", sans-serif !important;
  color: white !important;
}
.gallerySettingsTextFormTextInput {
  margin-bottom: 10px !important;
}
.gallerySettingsTextFormTextInputProps {
  color: white !important;
}
.gallerySettingsTextFormTextInputProps:before {
  border-bottom: 1px solid lightgray !important;
}
.gallerySettingsTextFormTextInputProps:after {
  border-bottom: 2px solid white !important;
}
.gallerySettingsTextFormTextInputLabel {
  color: #9e9e9e !important;
}
.gallerySettingsTextFormTextInputLabel:focus {
  color: white !important;
}
.gallerySettingsTextFormSubmitButton {
  background-color: #007DC5 !important;
  color: white !important;
  display: block;
  width: fit-content;
  width: 100%;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.gallerySettingsDivider {
  margin: 10px 0 !important;
  border-bottom: 0.75px solid white !important;
}
.gallerySettingsAddImageButton {
  background-color: #007DC5 !important;
  color: white !important;
  display: block;
  margin-bottom: 10px !important;
  margin-top: 10px !important;
  width: 100%;
}

