@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader {
  border: 16px solid #FFFFFF; /* Light grey */
  border-top: 16px solid #EA5F9D; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 1.3s linear infinite;
  scale: 50%;
}

.loadingContainerMobileItem {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}

