@import "../../../resources/css/colors";
@import "../../../resources/css/breakpoints";

.editEventDialog {
  &Paper {
    background-color: $fandic-background !important;
    border-radius: 0 !important;
    overflow: visible !important;
  }

  &Title {
    position: absolute;
    left: -20px;
    top: -20px;
    background-color: $fandic-secondary-light;
    width: fit-content;
    margin-bottom: 20px;
    padding: 12px 18px;

    font-size: 20px !important;
    font-weight: 700 !important;
    font-family: "Roboto Mono", sans-serif !important;
    color: $fandic-text;
  }

  &Content {
    margin-top: 80px;
  }

  &Input {
    margin-bottom: 20px !important;

    &Title {
      margin-bottom: 10px !important;
      font-size: 20px !important;
      font-weight: 600 !important;
      font-family: "Roboto Mono", sans-serif !important;
      color: $fandic-text !important;
    }

    &TitleSmall {
      margin-bottom: 20px !important;
      font-size: 18px !important;
      font-weight: 600 !important;
      font-family: "Roboto Mono", sans-serif !important;
      color: $fandic-text !important;
    }

    &Input {
      color: white !important; // Farbe des eingegebenen Textes

      &:before {
        border-bottom: 1px solid lightgray !important; // Farbe der Linie im nicht fokussierten Zustand
      }

      &:after {
        border-bottom: 2px solid white !important; // Farbe der Linie im fokussierten Zustand
      }
    }

    &Label {
      color: lightgray !important; // Farbe des Labels im nicht fokussierten Zustand

      &:focus {
        color: white !important; // Farbe des Labels im fokussierten Zustand
      }
    }
  }

  &Map {
    margin-bottom: 20px !important;
    width: 100%;
    height:199px;
  }

  &InputButton {
    margin-bottom: 20px !important;
    width: 100% !important;
    background-color: $fandic-secondary !important;
    color: $fandic-text !important;
    border-radius: 0 !important;

    &Delete {
        background-color: $fandic-danger !important;
    }
  }
}