@import "../../../resources/css/colors";
@import "../../../resources/css/breakpoints";

.eventCalendarSettings {
  &ImageTitle {
    margin-bottom: 25px !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    font-family: "Roboto Mono", sans-serif !important;
    color: $fandic-text !important;
  }

  &ImageForm {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &CurrentImage {
      max-width: 100%;
      max-height: 30vh;
      object-fit: cover;
      margin-bottom: 10px;
    }

    &Info {
      margin-top: 10px !important;

      &Confirm {
        color: #4dda4d !important;
        font-size: 14px !important;
      }

      &Error {
        color: #a91919 !important;
        font-size: 14px !important;
      }
    }

    &PreviewText {
      font-size: 14px !important;
      font-weight: 700 !important;
      font-family: "Roboto Mono", sans-serif !important;
      color: $fandic-text-secondary !important;
      margin-bottom: 5px !important;
    }

    &FileUploadButton {
      background-color: $fandic-secondary !important; // Hintergrundfarbe des Buttons
      color: $fandic-text !important; // Textfarbe des Buttons
      display: block;
      margin-top: 10px !important;
      width: 100%;
    }

    &SubmitButton {
      background-color: $fandic-secondary !important; // Hintergrundfarbe des Buttons
      color: $fandic-text !important; // Textfarbe des Buttons
      display: block;
      margin-top: 10px !important;
      margin-bottom: 10px !important;
      width: 100%;
    }
  }

  &Divider {
    margin-top: 10px !important;
    margin-bottom: 20px !important;
    border-bottom: 0.75px solid $fandic-divider !important;
  }

  &EventsTitle{
    font-size: 18px !important;
    font-weight: 700 !important;
    font-family: "Roboto Mono", sans-serif !important;
    color: $fandic-text !important;
    margin-bottom: 10px !important;
  }

  &AddEventButton {
    background-color: $fandic-secondary !important; // Hintergrundfarbe des Buttons
    color: $fandic-text !important; // Textfarbe des Buttons
    display: block;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    width: 100%;
  }
}