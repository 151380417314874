.notFoundBox {
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
}
.notFoundBoxTitle {
  margin-top: 10px !important;
  color: white !important;
  width: fit-content;
  padding: 12px 20px;
  font-size: 70px !important;
  font-weight: 400 !important;
  font-family: "Roboto Mono", sans-serif !important;
  background-color: #EA5F9D;
}
.notFoundBoxText {
  font-size: 16px !important;
  font-weight: normal !important;
  font-family: "Inconsolata", "sans-serif" !important;
  text-align: center;
  color: white;
}
.notFoundBoxText1 {
  margin-top: 20px !important;
  font-size: 24px !important;
}

