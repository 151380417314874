.eventCalendarSettingsImageTitle {
  margin-bottom: 25px !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  font-family: "Roboto Mono", sans-serif !important;
  color: white !important;
}
.eventCalendarSettingsImageForm {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.eventCalendarSettingsImageFormCurrentImage {
  max-width: 100%;
  max-height: 30vh;
  object-fit: cover;
  margin-bottom: 10px;
}
.eventCalendarSettingsImageFormInfo {
  margin-top: 10px !important;
}
.eventCalendarSettingsImageFormInfoConfirm {
  color: #4dda4d !important;
  font-size: 14px !important;
}
.eventCalendarSettingsImageFormInfoError {
  color: #a91919 !important;
  font-size: 14px !important;
}
.eventCalendarSettingsImageFormPreviewText {
  font-size: 14px !important;
  font-weight: 700 !important;
  font-family: "Roboto Mono", sans-serif !important;
  color: #9e9e9e !important;
  margin-bottom: 5px !important;
}
.eventCalendarSettingsImageFormFileUploadButton {
  background-color: #007DC5 !important;
  color: white !important;
  display: block;
  margin-top: 10px !important;
  width: 100%;
}
.eventCalendarSettingsImageFormSubmitButton {
  background-color: #007DC5 !important;
  color: white !important;
  display: block;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  width: 100%;
}
.eventCalendarSettingsDivider {
  margin-top: 10px !important;
  margin-bottom: 20px !important;
  border-bottom: 0.75px solid white !important;
}
.eventCalendarSettingsEventsTitle {
  font-size: 18px !important;
  font-weight: 700 !important;
  font-family: "Roboto Mono", sans-serif !important;
  color: white !important;
  margin-bottom: 10px !important;
}
.eventCalendarSettingsAddEventButton {
  background-color: #007DC5 !important;
  color: white !important;
  display: block;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  width: 100%;
}

