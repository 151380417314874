.editEventDialogPaper {
  background-color: #0C090A !important;
  border-radius: 0 !important;
  overflow: visible !important;
}
.editEventDialogTitle {
  position: absolute;
  left: -20px;
  top: -20px;
  background-color: #2387D0;
  width: fit-content;
  margin-bottom: 20px;
  padding: 12px 18px;
  font-size: 20px !important;
  font-weight: 700 !important;
  font-family: "Roboto Mono", sans-serif !important;
  color: white;
}
.editEventDialogContent {
  margin-top: 80px;
}
.editEventDialogInput {
  margin-bottom: 20px !important;
}
.editEventDialogInputTitle {
  margin-bottom: 10px !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  font-family: "Roboto Mono", sans-serif !important;
  color: white !important;
}
.editEventDialogInputTitleSmall {
  margin-bottom: 20px !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  font-family: "Roboto Mono", sans-serif !important;
  color: white !important;
}
.editEventDialogInputInput {
  color: white !important;
}
.editEventDialogInputInput:before {
  border-bottom: 1px solid lightgray !important;
}
.editEventDialogInputInput:after {
  border-bottom: 2px solid white !important;
}
.editEventDialogInputLabel {
  color: lightgray !important;
}
.editEventDialogInputLabel:focus {
  color: white !important;
}
.editEventDialogMap {
  margin-bottom: 20px !important;
  width: 100%;
  height: 199px;
}
.editEventDialogInputButton {
  margin-bottom: 20px !important;
  width: 100% !important;
  background-color: #007DC5 !important;
  color: white !important;
  border-radius: 0 !important;
}
.editEventDialogInputButtonDelete {
  background-color: #be3939 !important;
}

