.contactForm {
  background-color: #0C090A !important;
  padding: 40px !important;
}
@media (min-width: 1200px) {
  .contactForm {
    margin: 20px 150px !important;
  }
}
@media (max-width: 1199px) {
  .contactForm {
    margin: 20px 20px !important;
  }
}
.contactFormTitle {
  padding: 12px 20px;
  font-size: 30px !important;
  font-weight: 700 !important;
  font-family: "Roboto Mono", sans-serif !important;
  width: fit-content;
  margin-bottom: 30px !important;
  color: white;
  background-color: #EA5F9D;
}
.contactFormText {
  font-size: 16px !important;
  font-weight: normal !important;
  font-family: "Inconsolata", "sans-serif" !important;
  margin-bottom: 20px !important;
  color: white;
}
.contactFormSubTitle {
  margin-top: 45px;
  margin-bottom: 10px;
  font-size: 25px !important;
  font-weight: 700 !important;
  font-family: "Roboto Mono", sans-serif !important;
  color: white !important;
}
.contactFormInput {
  margin-bottom: 20px;
}
.contactFormInput .contactInput {
  color: white !important;
}
.contactFormInput .contactInput:before {
  border-bottom: 1px solid lightgray !important;
}
.contactFormInput .contactInput:after {
  border-bottom: 2px solid white !important;
}
.contactFormInput .contactInputLabel {
  color: lightgray !important;
}
.contactFormInput .contactInputLabel:focus {
  color: white !important;
}
.contactFormSubmit {
  background-color: #007DC5 !important;
  color: white !important;
  text-align: right !important;
  display: block !important;
  width: fit-content !important;
  margin-left: auto !important;
  margin-top: 20px !important;
}

