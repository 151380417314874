@import "../../resources/css/colors";
@import "../../resources/css/breakpoints";

.bookingContainerMobile {
  padding: 0;

  &Item {
    padding: 0;
    height: fit-content;
  }

  .bookingGrid {
    position: relative;
    background-color: $fandic-background;
    //box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.4);

    &ImageContainer {
      position: relative;
      width: 100%;

      &Image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &Title {
        position: absolute;
        left: 20px;
        bottom: -20px;
        padding: 12px 20px;
        font-size: 25px !important;
        font-weight: 700 !important;
        font-family: "Roboto Mono", sans-serif !important;
        width: fit-content;

        color: $fandic-text;
        background-color: $fandic-primary;
      }
    }

    &TextContainer {
      padding: 45px 40px 40px;

      &Text {
        font-size: 16px !important;
        font-weight: normal !important;
        font-family: "Inconsolata", "sans-serif" !important;

        color: $fandic-text;
      }

      .bookingForm {

        &Title {
          margin-top: 45px;
          margin-bottom: 10px;
          font-size: 25px !important;
          font-weight: 700 !important;
          font-family: "Roboto Mono", sans-serif !important;
          color: $fandic-text !important;
        }

        &MessageInfo {
          &Confirm {
            color: #4dda4d !important;
            font-size: 14px !important;
          }

          &Error {
            color: #a91919 !important;
            font-size: 14px !important;
          }
        }

        &Input {
          margin-bottom: 20px;

          .bookingInput {
            color: white !important; // Farbe des eingegebenen Textes

            &:before {
              border-bottom: 1px solid lightgray !important; // Farbe der Linie im nicht fokussierten Zustand
            }

            &:after {
              border-bottom: 2px solid white !important; // Farbe der Linie im fokussierten Zustand
            }
          }

          .bookingInputLabel {
            color: lightgray !important; // Farbe des Labels im nicht fokussierten Zustand

            &:focus {
              color: white !important; // Farbe des Labels im fokussierten Zustand
            }
          }
        }

        &Submit {
          background-color: $fandic-secondary !important; // Hintergrundfarbe des Buttons
          color: $fandic-text !important; // Textfarbe des Buttons
          text-align: right;
          display: block;
          width: fit-content;
          margin-left: auto;
          margin-top: 10px;
        }
      }
    }
  }
}