@import "../../../resources/css/colors";
@import "../../../resources/css/breakpoints";

.milestoneListItem {
  &Button {
    width: 100% !important;

    &Title {
      font-size: 18px !important;
      font-weight: 600 !important;
      font-family: "Roboto Mono", sans-serif !important;
      color: $fandic-text !important;
      margin-right: 15px !important;
    }

    &IconContainer {

      &Image {
        max-width: 25vw;
        max-height: 10vh;
      }
    }

    &Text {
      margin-left: 20px !important;

      &Primary {
        font-size: 18px !important;
        font-weight: 500 !important;
        font-family: "Roboto", sans-serif !important;
        color: $fandic-text !important;
      }

      &Secondary {
        font-size: 14px !important;
        font-family: "Roboto", sans-serif !important;
        color: $fandic-text !important;
      }
    }
  }

  &PositionInput {
    width: 50px !important;
    margin-left: 10px !important;
  }
}