$fandic-background: #0C090A;
$fandic-background-light: #1A1A1A;
$fandic-background-dark: #050505;

$fandic-primary: #EA5F9D;
$fandic-primary-hover: #D94D8A;
$fandic-secondary: #007DC5;
$fandic-secondary-light: #2387D0;
$fandic-danger: #be3939;
$fandic-white: #FFFFFF;

$fandic-text: white;
$fandic-text-secondary: #9e9e9e;
$fandic-text-link : #007dc5;

$fandic-divider: white;
$fandic-divier-secondary: #9e9e9e;
$fandic-divider-dark: #3a3a3a;

$fandic-shadow: #000000;

$facebook: #3B5998;
$instagram: #E1306C;
$soundcloud: #FF5500;
$twitch: #6441A5;
$phoneGreen: #4CAF50;
$contactForm: #007DC5;
