@import "../../../resources/css/colors";
@import "../../../resources/css/breakpoints";

.gallerySettings {
  &TextTitle {
    margin-bottom: 25px !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    font-family: "Roboto Mono", sans-serif !important;
    color: $fandic-text !important;
  }

  &TextForm {

    &TextInput {

      margin-bottom: 10px !important;

      &Props {
        color: $fandic-text !important; // Farbe des eingegebenen Textes

        &:before {
          border-bottom: 1px solid lightgray !important; // Farbe der Linie im nicht fokussierten Zustand
        }

        &:after {
          border-bottom: 2px solid $fandic-text !important; // Farbe der Linie im fokussierten Zustand
        }
      }

      &Label {
        color: $fandic-text-secondary !important; // Farbe des Labels im nicht fokussierten Zustand

        &:focus {
          color: $fandic-text !important; // Farbe des Labels im fokussierten Zustand
        }
      }
    }

    &SubmitButton {
      background-color: $fandic-secondary !important; // Hintergrundfarbe des Buttons
      color: $fandic-text !important; // Textfarbe des Buttons
      display: block;
      width: fit-content;
      width: 100%;
      margin-top: 10px !important;
      margin-bottom: 10px !important;
    }
  }

  &Divider {
    margin: 10px 0 !important;
    border-bottom: 0.75px solid $fandic-divider !important;
  }

  &AddImageButton {
    background-color: $fandic-secondary !important; // Hintergrundfarbe des Buttons
    color: $fandic-text !important; // Textfarbe des Buttons
    display: block;
    margin-bottom: 10px !important;
    margin-top: 10px !important;
    width: 100%;
  }
}