.loginBox {
  margin: 80px 40px 40px;
}
.loginBoxTitle {
  margin-top: 45px;
  margin-bottom: 10px;
  font-size: 25px !important;
  font-weight: 700 !important;
  font-family: "Roboto Mono", sans-serif !important;
  color: white !important;
}
.loginBox .loginFormTitle {
  margin-top: 45px;
  margin-bottom: 10px;
  font-size: 25px !important;
  font-weight: 700 !important;
  font-family: "Roboto Mono", sans-serif !important;
  color: white !important;
}
.loginBox .loginFormMessageInfo {
  margin-bottom: 10px;
}
.loginBox .loginFormMessageInfoConfirm {
  color: #4dda4d !important;
  font-size: 14px !important;
}
.loginBox .loginFormMessageInfoError {
  color: #a91919 !important;
  font-size: 14px !important;
}
.loginBox .loginFormInput {
  margin-bottom: 20px;
}
.loginBox .loginFormInputInput {
  color: white !important;
}
.loginBox .loginFormInputInput:before {
  border-bottom: 1px solid lightgray !important;
}
.loginBox .loginFormInputInput:after {
  border-bottom: 2px solid white !important;
}
.loginBox .loginFormInputLabel {
  color: lightgray !important;
}
.loginBox .loginFormInputLabel:focus {
  color: white !important;
}
.loginBox .loginFormSubmit {
  background-color: #007DC5 !important;
  color: white !important;
  text-align: right;
  display: block;
  width: fit-content;
  margin-left: auto;
  margin-top: 10px;
}
.loginBox .logoutFormTitle {
  margin-top: 45px;
  margin-bottom: 10px;
  font-size: 25px !important;
  font-weight: 700 !important;
  font-family: "Roboto Mono", sans-serif !important;
  color: white !important;
}
.loginBox .logoutFormMessageContainer {
  margin-top: 20px;
  margin-bottom: 20px;
}
.loginBox .logoutFormMessageContainerMessage {
  font-family: Inconsolata, sans-serif !important;
  color: white !important;
  font-size: 14px !important;
}
.loginBox .logoutFormSubmit {
  background-color: #007DC5 !important;
  color: white !important;
  text-align: right !important;
  display: inline-block !important;
  width: fit-content !important;
  margin-left: 10px !important;
  margin-top: 10px !important;
}
.loginBox .logoutFormGoToSettings {
  background-color: #007DC5 !important;
  color: white !important;
  text-align: right !important;
  display: inline-block !important;
  width: fit-content !important;
  margin-left: auto !important;
  margin-top: 10px !important;
}
.loginBox .logoutFormButtonContainer {
  display: flex !important;
  justify-content: space-between !important;
}

