.contactSettingImageTitle {
  margin-bottom: 25px !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  font-family: "Roboto Mono", sans-serif !important;
  color: white !important;
}
.contactSettingImageForm {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.contactSettingImageFormCurrentImage {
  max-width: 100%;
  max-height: 30vh;
  object-fit: cover;
  margin-bottom: 10px;
}
.contactSettingImageFormPreviewText {
  font-size: 14px !important;
  font-weight: 700 !important;
  font-family: "Roboto Mono", sans-serif !important;
  color: #9e9e9e !important;
  margin-bottom: 5px !important;
}
.contactSettingImageFormFileUploadButton {
  background-color: #007DC5 !important;
  color: white !important;
  display: block;
  margin-top: 20px !important;
  width: 100%;
}
.contactSettingImageFormSubmitButton {
  background-color: #007DC5 !important;
  color: white !important;
  display: block;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  width: 100%;
}
.contactSettingDivider {
  margin: 20px 0 !important;
  border-bottom: 0.75px solid white !important;
}
.contactSettingTextTitle {
  margin: 25px 0 !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  font-family: "Roboto Mono", sans-serif !important;
  color: white !important;
}
.contactSettingTextFormTextInputProps {
  color: white !important;
}
.contactSettingTextFormTextInputProps:before {
  border-bottom: 1px solid lightgray !important;
}
.contactSettingTextFormTextInputProps:after {
  border-bottom: 2px solid white !important;
}
.contactSettingTextFormTextInputLabel {
  color: #9e9e9e !important;
}
.contactSettingTextFormTextInputLabel:focus {
  color: white !important;
}
.contactSettingTextFormSubmitButton {
  background-color: #007DC5 !important;
  color: white !important;
  display: block;
  margin-top: 20px !important;
  margin-bottom: 10px !important;
  width: 100%;
}

